import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import GatsbyImage from 'gatsby-image';
import MarkdownConverter from '../atoms/MarkdownConverter';

const Holder = styled.div`
  width: 100%;
  height: 100%;
  position: relative;
  video {
    width: 100%;
    height: auto;
    max-width: 80rem;
    max-height: 100%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
`;

const EmbedHolder = styled.div`
  width: 100%;
  height: 100%;
  position: relative;
  
  .markdownConverter {
    position: absolute; 
    padding-bottom: 56.25%; 
    height: 0; 
    overflow: hidden; 
    width: 100%; 
    max-width: 80rem;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    
    iframe, 
    object, 
    embed { 
      position: absolute; 
      top: 0; 
      left: 0; 
      width: 100%; 
      height: 100%; 
    }
  }
`;

function SlideShowItem( { item } ) {
  return (
    <>

      {item.embedCode && <EmbedHolder><MarkdownConverter content={item.embedCode.embedCode}/></EmbedHolder>}

      {item.file && item.file.contentType.includes( 'video' ) && <Holder>
        <video width="100%" height="100%" controls>
          <source src={item.file.url} type={item.file.contentType}/>
          Your browser does not support the video tag.
        </video>
      </Holder>}

      {item.fluid && <GatsbyImage
        style={{
          height: '100%',
          width: '100%',
        }}
        imgStyle={{
          objectFit: 'contain',
          objectPosition: 'center',
        }}
        loading="eager"
        fluid={item.fluid}/>}

    </>
  )
}

SlideShowItem.propTypes = {
  item: PropTypes.object
};

export default SlideShowItem;
