import React from 'react';
import styled from 'styled-components';
import { CSSTransition } from 'react-transition-group';
import { PropTypes } from 'prop-types';
import IconCross from '../atoms/iconCross';
import IconArrow from '../atoms/iconArrow';

const Holder = styled.div`
  display: none;
  flex-direction: column;
  position: fixed;
  z-index: 100;
  background-color: ${props => props.theme.colours.black};
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  align-items: center;
  justify-content: space-between;
  @media( ${props => props.theme.breakpoints.sm} ) {
    display: block;
  }
  
  &.slideshow-appear,
  &.slideshow-enter {
    opacity: 0;
  }
  &.slideshow-appear-active, &.slideshow-appear-done,
  &.slideshow-enter-active, &.slideshow-enter-done {
    opacity: 1;
    transition: opacity 250ms;
  }
  &.slideshow-exit {
    opacity: 1;
  }
  &.slideshow-exit-active {
    opacity: 0;
    transition: opacity 250ms;
  }
  
  .title {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    text-align: center;
    margin: 0;
    line-height: 8rem;
  }
  
  .count {
    position: absolute;
    bottom: 2rem;
    left: 2rem;
    margin: 0;
  }
  
  button {
    position: absolute;
    z-index: 10;
    &.prev{
      top: 50%;
      transform: translateY(-50%);
      left: 2rem;
      svg {
        transform: rotate(180deg);
      }
    }
    &.next{
      top: 50%;
      transform: translateY(-50%);
      right: 2rem;
    }
    &.close{
      top: 2rem;
      right: 2rem;
    }
    svg {
      line, polyline {
        stroke: ${props => props.theme.colours.white};
      }
    }
  }
`;

const Slide = styled.div`
  position: absolute;
  z-index: 5;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  padding: 4rem;
  @media( ${props => props.theme.breakpoints.sm } ) {
    padding: 8rem;
  }
  &.slide-appear,
  &.slide-enter {
    opacity: 0;
  }
  &.slide-appear-active, &.slide-appear-done,
  &.slide-enter-active, &.slide-enter-done {
    opacity: 1;
    transition: all 250ms;
  }
  &.slide-exit {
    opacity: 1;
  }
  &.slide-exit-active {
    opacity: 0;
    transition: all 250ms;
  }
`;


function SlideShow( { client, children, show, closeHandler, setActiveSlide, active } ) {

  const childrenArray = React.Children.toArray( children );

  const nextSlide = ( i ) => {
    if ( i < childrenArray.length - 1 ) {
      setActiveSlide( i + 1 );
    } else {
      setActiveSlide( 0 );
    }
  };

  const prevSlide = ( i ) => {
    if ( i === 0 ) {
      setActiveSlide( childrenArray.length - 1 );
    } else {
      setActiveSlide( i - 1 );
    }
  };

  return (
    <CSSTransition
      mountOnEnter
      unmountOnExit
      appear
      in={show}
      timeout={250}
      classNames="slideshow">
      <Holder>
        {client && <p className="p title"><b>{client}</b></p>}
        <button className="prev icon small" title="Previous image" aria-label="Previous image" onClick={() => { prevSlide( active ) }}><IconArrow/></button>
        {childrenArray.map(
          ( child, i ) => (
            <CSSTransition
              key={i}
              mountOnEnter
              unmountOnExit
              appear
              in={i === active}
              timeout={250}
              classNames="slide">
              <Slide>
                {child}
              </Slide>
            </CSSTransition>
          ) )}
        <button className="next icon small" title="Next image" aria-label="Next image" onClick={() => { nextSlide( active ) }}><IconArrow/></button>
        <button className="close icon small" title="Close slide-show" aria-label="Close slide-show" onClick={() => { closeHandler() }}><IconCross/></button>
        <p className="count">{active} / {childrenArray.length}</p>
      </Holder>
    </CSSTransition>
  )
}

SlideShow.propTypes = {
  client: PropTypes.string,
  show: PropTypes.bool.isRequired,
  closeHandler: PropTypes.func.isRequired,
  active: PropTypes.number.isRequired,
  setActiveSlide: PropTypes.func.isRequired
};

SlideShow.defaultProps = {
  show: false
};

export default SlideShow;
