import React from 'react';

function IconArrow() {
  return (
    <svg width="60px" height="60px" viewBox="0 0 60 60" xmlns="http://www.w3.org/2000/svg">
      <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd" strokeLinecap="round" strokeLinejoin="round">
        <polyline stroke="#383838" strokeWidth="7" points="19 4 45.1445313 30.1445312 19.3652344 56"/>
      </g>
    </svg>
  )
}

export default IconArrow;
